import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const ReconciliationApiService = {
  async process({ type, dryRun, file = null }) {
    try {
      const { data: response } = await HTTP.post(`reconciliation/${type}`, file, {
        params: {
          dryRun,
        },
      });

      return response || {};
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getTypes() {
    try {
      const { data } = await HTTP.get('reconciliation/types');

      return Promise.resolve(data || []);
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};

export default {};
