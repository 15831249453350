<template>
  <vbt-content-box
    :loading="typesLoading"
    title="Reconciliation"
  >
    <v-layout align-center>

      <v-flex
        class="px-4"
        sm3
      >
        <reconciliation-selector
          v-model="type"
          :items="types"
          :descriptions="descriptionsByKey"
        />

      </v-flex>

      <v-flex sm3>
        <v-file-input
          class="mt-3"
          label="File"
          @change="setFile"
        />
      </v-flex>

      <v-flex sm2 ml-5>
        <v-checkbox
          v-model="dryRun"
          class="vbt-hide-form-control-details mt-2 mr-5"
          label="Dry run"
        />
      </v-flex>

      <v-btn
        color="success"
        class="mt-4 ml-10"
        small
        @click="process"
      >
        Process
      </v-btn>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';

import { ReconciliationApiService } from '../_services/reconciliation.api.service';

import ReconciliationSelector from '../_components/ReconciliationSelector.vue';

export default {
  name: 'IrReconciliation',

  components: {
    ReconciliationSelector,
  },

  data() {
    return {
      file: null,
      dryRun: true,
      type: '',
      types: [],
      reconciliationFile: '',

      typesLoading: false,
    };
  },

  computed: {
    descriptionsByKey() {
      return Object.keys(this.types).reduce((acc, key) => {
        acc[key] = this.types[key].description;

        return acc;
      }, {});
    },
  },

  created() {
    this.getTypes();
  },

  methods: {
    process() {
      const formData = new FormData();

      if (this.reconciliationFile) {
        formData.append('file', this.reconciliationFile, this.reconciliationFile.name);
      }

      this.wrapToLoadingFn({
        req: ReconciliationApiService.process.bind({}, {
          file: this.reconciliationFile ? formData : null,
          type: this.type,
          dryRun: this.dryRun,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$router.push({ name: 'reports' });
        },
      });
    },

    getTypes() {
      this.wrapToLoadingFn({
        req: ReconciliationApiService.getTypes,
        loadingFlagName: 'typesLoading',
        onSuccess: (r) => {
          this.types = r;
          this.type = r[0].type;
        },
      });
    },

    setFile(file) {
      this.reconciliationFile = file;
    },

    wrapToLoadingFn,
  },
};
</script>
